<template>
  <div
    class="fixed w-72 right-0 h-full bg-white rounded-l-2xl p-5 pt-0 overflow-y-auto overflow-x-hidden"
  >
    <div class="flex my-5">
      <div
        class="bg-red-500 bg-opacity-30 p-3 mt-5 rounded-full cursor-pointer"
        @click="$emit('close')"
      >
        <CloseIcon />
      </div>
    </div>
    <img :src="staff.image" class="rounded-full" />
    <h1 class="mt-5 font-bold text-3xl text-center">
      {{ staff.firstname }}, {{ staff.lastname }}
    </h1>
    <h2 class="text-lg text-center text-gray-600 pb-5 mb-5 border-b">
      {{ staff.staffroles[0].user_type_name }}
    </h2>
    <h3 class="font-bold mb-3">Contact Info</h3>
    <div class="flex gap-3 border-b py-2 mb-3">
      <message-icon />
      <div class="">
        {{ staff.email }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <phone-icon />
      <div class="">
        {{ staff.phone }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <location-icon />
      <div class="">
        {{ staff.address }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <user-icon class="opacity-50" />
      <div class="">{{ staff.staffroles[0].user_type_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditstaffModal",
  props: {
    staff: {
      required: true,
      type: Object,
    },
  },
  components: {
    MessageIcon: () => import("@/assets/icons/MessageIcon.vue"),
    PhoneIcon: () => import("@/assets/icons/PhoneIcon.vue"),
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
    UserIcon: () => import("@/assets/icons/UserIcon.vue"),
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
};
</script>

<style></style>
